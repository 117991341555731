import _ from 'lodash';
import { translationDictionary } from './SlugParser';

// per saber si les friendlies han de tenir un prefix entre el basePath i la url friendly
const _productPrefix = {
    web_noticies: '/3catinfo',
    web_3cat: '/3cat'
};

// per saber si hem de fer una url friendly (server) o amb querystring (static/export)
const _routePathType = {
    web: ''
};

const _routePathJitPub1 = {
    web_esports: 'https://www.3cat.cat/esport3/',
    web_esports_item_noticia: 'https://www.3cat.cat/esport3/{slug}/noticia/{id}/',
    web_esports_tema: 'https://www.3cat.cat/esport3/{slug}/tema/{slug2}/',
    web_programa_podcast: 'https://www.3cat.cat/3cat/el-mati-de-catalunya-radio/'
};

const _routePathJitPub2 = {
    web_actualitat_item_noticia: '/{slug}/noticia/{id}/',
    web_actualitat_item_noticia_amp: '/{slug}/noticia-amp/{id}/',
    web_actualitat_item_autor: '/{slug}/autor/{id}/',
    web_actualitat_item_minutaminut: '/{slug}/directe/{id}/',
    web_actualitat_home: '/',
    web_actualitat_seccio: '/{slug}/',
    web_actualitat_seccio_extra: '/{slug}/?{params}',
    web_actualitat_qa_blocdecontinguts: '/qa/blocdecontinguts/{slug}/',
    web_3catinfo_shared_reel: '/reels/?shared_video={id}&reels={slug}&url={slug2}',

    web_3catinfo_el_temps: '/el-temps/',
    web_3catinfo_el_temps_seccio: '/el-temps/{slug}/',
    web_3catinfo_el_temps_previsio: '/el-temps/previsio/{slug}/',
    web_3catinfo_el_temps_municipi: '/el-temps/{slug}/previsio/{id}/',
    web_3catinfo_el_temps_alta_estacio: '/el-temps/alta-estacio-meteorologica/',
    web_3catinfo_el_temps_embed_camera: '/el-temps/{slug}/cameraembed/{id}/',

    web_3catinfo_tendencies_climatiques_municipi: '/tendencies-climatiques/{slug}/municipi/{id}/',

    web_3catinfo_interactiu: '/{slug}/interactiu/{id}/',

    web_3catinfo_estatiques: '/estatiques/{slug}/',
    web_3catinfo_estatiques_subseccio: '/estatiques/{slug}/{slug2}/',

    web_3catinfo_meu3catinfo: '/meu3catinfo/',
    web_3catinfo_meu3catinfo_seccio: '/meu3catinfo/{slug}/'
};

const _routePathJitPub2_3cat = {
    web_3cat_programa: '/{slug}',
    web_3cat_item_video: '/{slug}/video/{id}/',
    web_3cat_audio_item: '/{slug}/audio/{id}/',
    web_3cat_directe_item_tv3: '/directes/tv3/',
    web_3cat_directe_item_324: '/directes/324/',
    web_3cat_directe_item_esport3: '/directes/esport3/',
    web_3cat_directe_item_sx3: '/directes/sx3/',
    web_3cat_directe_item_33: '/directes/33/',
    web_3cat_directe_item_cr: '/directes/catalunya-radio/',
    web_3cat_directe_item_ci: '/directes/catalunya-informacio/',
    web_3cat_directe_item_cm: '/directes/catalunya-musica/',
    web_3cat_directe_item_ic: '/directes/icat/',
    web_3cat_directe_item_ocr1: '/directes/ocr1/',
    web_3cat_directe_item_oca1: '/directes/oca1/',
    web_3cat_directe_item_oca2: '/directes/oca2/',
    web_3cat_directe_item_oca3: '/directes/oca3/',
    web_3cat_directe_item_oca4: '/directes/oca4/'
};

const _routePaths = { ..._routePathJitPub1, ..._routePathJitPub2, ..._routePathJitPub2_3cat };

const _getSlug = (cadena) => {
    let replaced;
    const translationDictionaryRegex = RegExp('[' + _.keys(translationDictionary).join('') + ']', 'gi');
    replaced = cadena.replace(translationDictionaryRegex, function (m) {
        let ret = translationDictionary[m.toUpperCase()];
        if (m === m.toLowerCase()) ret = ret.toLowerCase();
        return ret;
    });

    return replaced
        .replace(/[^a-zA-Z0-9\-_<>]/gi, '')
        .replace(/[\-]+/gi, '-')
        .replace(/<[^>]*>/gi, '')
        .replace(/^[-]/gi, '')
        .replace(/[-]$/gi, '')
        .toLowerCase();
};

/**
 * id: id del contingut
 * tipologia: tipologia del contingut: (dty_video_mm|dty_colleccio|wcr_audio_mm|not_noticia|dty_galeria|wcr_programa|ptvc_programa|dty_especial)
 * domini: domini del contingut: (tvc|ptvc|pucr|wrc|sense|n324|not|pue3|esp|met|pucc|pusp|cultu|pus3|pudoc)
 * slug: titol del contingut, escapat en-format-url
 * slug2: titol del contingut relacionat, escapat en-format-url
 * tipologiaRelacionada: tipologia relacionada que condiciona el contingut: (wcr_programa|ptvc_programa|programa|not_autor|undefined)
 * idRelacio: id de la tipologia relacionada
 * canal: tv3|super3|
 * categoria: id de la categoria
 * producte: web|app|smarttv|hbbtv|webview
 * routePath: força emprar el patró de friendly indicat explícitament
 * tipusDirecte: tv|radio,
 * agrupadores: agrupacions associades
 **/
const getUrlFriendly = (props) => {
    let { id, slug, slug2, params, tipologia, routePath, producte, domini } = props;
    try {
        if (routePath && typeof _routePathType[producte] !== 'undefined') {
            routePath += _routePathType[producte];
        }

        slug = slug ? _getSlug(slug) : 'titol';
        slug2 = slug2 ? _getSlug(slug2) : 'titol';

        if (!(routePath && _routePaths[routePath])) {
            switch (tipologia) {
                case 'NOT_NOTICIA':
                    switch (domini) {
                        case 'PUE3':
                            routePath = 'web_esports_item_noticia';
                            break;
                        default:
                            routePath = 'web_actualitat_item_noticia';
                            break;
                    }
                    break;
                case 'NOT_AUTOR':
                    routePath = 'web_actualitat_item_autor';
                    break;
                case 'DEBAT_MINUT':
                    routePath = 'web_actualitat_item_minutaminut';
                    break;
                default:
                    return '#';
            }
        }
        producte = _routePathJitPub2_3cat.hasOwnProperty(routePath) ? 'web_3cat' : producte;
        routePath = _routePaths[routePath]
            .replace('{id}', id)
            .replace('{slug}', slug)
            .replace('{slug2}', slug2)
            .replace('{params}', params);

        return routePath.includes('https://') || routePath.includes('http://') ? routePath : _productPrefix[producte] + routePath;
    } catch (error) {
        console.log('Error Routeresolver', error);
        return '#';
    }
};

const getUrlCanonical = (props) => {
    const { id, routePath } = props;
    let { slug } = props;
    slug = slug ? _getSlug(slug) : 'titol';
    return _routePaths[routePath].replace('{id}', id).replace('{slug}', slug);
};

const getRouteType = (url) => {
    if (!url) return {};

    url = url.replace('/3catinfo', '');

    const convertToRegex = (pattern) => {
        return new RegExp('^' + pattern.replace(/{(\w+)}/g, '(?<$1>[^/]+)') + '$');
    };

    for (const [type, pattern] of Object.entries(_routePaths)) {
        const regexPattern = convertToRegex(pattern);
        const match = url.match(regexPattern);
        if (match) {
            return {
                type,
                ...match.groups
            };
        }
    }

    return {};
};

export { getRouteType, getUrlCanonical, getUrlFriendly };
