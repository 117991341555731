import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { getUrlFriendly } from './RouteResolver';
import styles from './enllac.module.scss';
import { forwardRef } from 'react';
 
const _getUrl = (props) => {
    const { type, route } = props;
    return getUrlFriendly({ ...props, ...route, routePath: route?.type || type, producte: 'web_noticies' });
};
 
const Enllac = forwardRef((props, ref) => {
    const { titol, estil, className, novaFinestra, route, rel, accesskey, dataTestId, ariaLabel, title, role, tabIndex, onClick } = props;
 
    const styleLink = clsx({
        [styles[estil]]: !__isEmpty(estil),
        [className]: !__isEmpty(className)
    });
 
    let href = route?.href || props.href;
    if (__isEmpty(href)) {
        href = _getUrl(props);
    }
 
    const linkContent = (
        <a
            onClick={onClick}
            className={styleLink ? styleLink : null}
            target={novaFinestra ? '_blank' : null}
            accessKey={accesskey}
            rel={novaFinestra ? 'noreferrer' : rel}
            data-testid={dataTestId}
            aria-label={ariaLabel}
            title={title}
            role={role}
            href={href}
            ref={ref}
            tabIndex={tabIndex}
        >
            {titol ? titol : props.children}
        </a>
    );
    if (href.startsWith('/3catinfo/')) {
        return (
            <Link href={href} scroll={false}>
                {linkContent}
            </Link>
        );
    } else {
        return linkContent;
    }
});
 
Enllac.defaultProps = {
    titol: '',
    href: '',
    estil: '',
    disponible: true,
    cleanUrlParams: false,
    novaFinestra: false
};
Enllac.displayName = 'Enllac';
export default Enllac;
